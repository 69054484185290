<template>
	<transition name="slideFromUp">
		<VuePerfectScrollbar class="fullPage color-white" v-if="openedMenu" :class="{opened:openedMenu}" :settings="scrollSettings">
			<div class="fullPage__body">
				<ul class="menu container">
					<li v-for="(link,index2) in menuLinks" :key="index2">
						<router-link :to="link.link" v-html="link.name" :exact="link.link === '/' ? true : false"></router-link>
					</li>
				</ul>
			</div>
			<div class="menuFooter container">
				<div class="menuSocials">
					<a href="https://www.instagram.com/artrockets.ru/" target="_blank"><span class="icon-inst2"></span></a>
					<a href="https://vk.com/artrockets" target="_blank"><span class="icon-vk"></span></a>
				</div>
				<div class="menuFooter__right">
					<div>
						<p>Казань, ул. Зур Урам, 1а</p>
					</div>
					<div>
						<a href="mailto:info@artrockets.ru" class="hover-red">info@artrockets.ru</a>
					</div>
					<div>
						<a href="/presentaton.pdf" target="_blank" class="hover-red">Наша презентация</a>
					</div>
				</div>
			</div>
		</VuePerfectScrollbar>
	</transition>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Menu'
</style>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import menuLinks from "@/assets/json/newMenuLinks.json";

	export default {
		props: ['openedMenu'],
		data: () => ({
			menuLinks,
			scrollSettings: {
				suppressScrollX: true
			},
		}),
		mounted(){
			
		},
		components:{
			VuePerfectScrollbar
		}
	}
</script>